import React from 'react';

const VideoSection = () => {
  const handleCTAClick = () => {
    window.dataLayer = window?.dataLayer || [];
    window.dataLayer.push({
      'event': 'Lead',
      'eventCategory': 'Ingresso',
      'eventAction': 'click',
      'eventLabel': '1° Parrillada Canto'
    });
    window.open('https://blackbirdsystem.com.br/evento/canto-1-ano-3011', '_blank');
  };

  return (
    <section className="py-20 bg-stone-900 relative overflow-hidden">
      <div className="container mx-auto px-4">
        <div className="max-w-6xl mx-auto">
          <div className="grid md:grid-cols-2 gap-8 items-center">
            {/* Coluna do Vídeo */}
            <div className="aspect-[9/16] w-full max-w-sm mx-auto md:max-w-none">
              <iframe
                className="w-full h-full rounded-xl shadow-2xl"
                src="https://www.youtube.com/embed/1KFRdPQ3iOY?autoplay=1&mute=0&controls=0&showinfo=0&rel=0&modestbranding=1&loop=1&playlist=1KFRdPQ3iOY&playsinline=1&enablejsapi=1"
                title="Vídeo Parrillada Canto"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>

            {/* Coluna do Texto */}
            <div className="space-y-6 text-center md:text-left">
              <h3 
                onClick={handleCTAClick}
                className="text-2xl font-bold text-amber-400 cursor-pointer hover:text-amber-300 
                         transition-colors duration-300"
              >
                A verdadeira experiência da parrilla
              </h3>
              
              <div className="space-y-4 text-gray-300">
                <p className="text-lg">
                  O evento será ao ar livre, em uma tarde de muitos assados,
                  boas conversas e ambiente familiar, como é o nosso espaço.
                </p>

                <ul className="space-y-4">
                  <li className="flex items-center gap-2">
                    <span className="text-amber-400 text-xl">✓</span>
                    <span>5 estações de parrilla com cortes nobres</span>
                  </li>
                  <li className="flex items-center gap-2">
                    <span className="text-amber-400 text-xl">✓</span>
                    <span>Costela de chão pela Rosa Brasil</span>
                  </li>
                  <li className="flex items-center gap-2">
                    <span className="text-amber-400 text-xl">✓</span>
                    <span>Shows ao vivo (ver no final da página)</span>
                  </li>
                  <li className="flex items-center gap-2">
                    <span className="text-amber-400 text-xl">✓</span>
                    <span>Espaço kids para toda família</span>
                  </li>
                  <li className="flex items-center gap-2">
                    <span className="text-amber-400 text-xl">✓</span>
                    <span>Drinks especiais e cervejas Ambev</span>
                  </li>
                </ul>

                <div className="mt-8 space-y-4 bg-[#843742]/10 p-6 rounded-lg backdrop-blur-sm">
                  <p className="text-lg leading-relaxed">
                    O evento marca 1 ano de Canto e será realizado no espaço Dunna, que fica localizado no Bigorrilho. 
                    Comemoraremos com um cardápio de respeito, trazendo nossos cortes argentinos, uruguaios e brasileiros, 
                    mostrando nossa essência com muito ancho, chorizo, entraña, matambrito, hambúrguer, bruschetta de costela, 
                    buffet de acompanhamentos e uma costela de fogo de chão em parceria com nosso amigo Rosa Brasil, que ganhou 
                    o prêmio Bom Gourmet 2024 como melhor restaurante Rural.
                  </p>
                  <p className="text-lg leading-relaxed">
                    Serão mais de 5 horas de Open Food.
                  </p>
                  <p className="text-lg leading-relaxed">
                    O bar será uma colab com o espaço Dunna, mesclando o melhor da coquetelaria do Canto, cervejas, chopp gelado e vinhos.
                  </p>
                </div>
              </div>

              <button
                onClick={handleCTAClick}
                className="mt-8 w-full md:w-auto px-8 py-4 bg-amber-500 hover:bg-amber-600 
                         text-white rounded-lg font-bold transition-all duration-300 
                         transform hover:scale-105 flex items-center justify-center gap-2
                         shadow-lg hover:shadow-xl"
              >
                <span>Comprar Ingresso</span>
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  fill="none" 
                  viewBox="0 0 24 24" 
                  strokeWidth={2} 
                  stroke="currentColor" 
                  className="w-5 h-5"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VideoSection;
