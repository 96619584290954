import React from 'react';
import carne from '../../../assets/images/CANTO - 600 X 400 px - 1.png';
import drink from '../../../assets/images/CANTO - 600 X 400 px - 2.png';
import tabua from '../../../assets/images/CANTO - 600 X 400 px - 3.png';

const Meio = () => {
  const handleCardClick = () => {
    window.dataLayer = window?.dataLayer || [];
    window.dataLayer.push({
      'event': 'Lead',
      'eventCategory': 'Ingresso',
      'eventAction': 'click',
      'eventLabel': '1° Parrillada Canto'
    });
    window.open('https://blackbirdsystem.com.br/evento/canto-1-ano-3011', '_blank');
  };

  return (
    <section className="py-20 bg-stone-900">
      <div className="container mx-auto px-4">
        <div className="max-w-6xl mx-auto space-y-16">
          <div className="grid md:grid-cols-3 gap-6 lg:gap-8">
            {/* Card 1 */}
            <div className="group relative overflow-hidden rounded-xl bg-[#843742]/10 hover:bg-[#843742]/20 transition-all duration-500">
              <div className="aspect-[3/2] overflow-hidden">
                <img 
                  src={carne} 
                  alt="Cortes nobres no Canto" 
                  className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-700"
                />
              </div>
              <div className="p-6 text-center">
                <h3 className="text-2xl font-bold text-amber-400 mb-3">Tradição & Sabor</h3>
                <p className="text-gray-300">Experimente os mais suculentos cortes de carne preparados na autêntica parrilla uruguaia.</p>
              </div>
              <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
            </div>

            {/* Card 2 */}
            <div className="group relative overflow-hidden rounded-xl bg-[#843742]/10 hover:bg-[#843742]/20 transition-all duration-500">
              <div className="aspect-[3/2] overflow-hidden">
                <img 
                  src={drink} 
                  alt="Drinks especiais" 
                  className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-700"
                />
              </div>
              <div className="p-6 text-center">
                <h3 className="text-2xl font-bold text-amber-400 mb-3">Drinks Exclusivos</h3>
                <p className="text-gray-300">Nossa carta de drinks foi especialmente desenvolvida para harmonizar com nossa gastronomia.</p>
              </div>
              <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
            </div>

            {/* Card 3 */}
            <div className="group relative overflow-hidden rounded-xl bg-[#843742]/10 hover:bg-[#843742]/20 transition-all duration-500">
              <div className="aspect-[3/2] overflow-hidden">
                <img 
                  src={tabua} 
                  alt="Tábua de carnes" 
                  className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-700"
                />
              </div>
              <div className="p-6 text-center">
                <h3 className="text-2xl font-bold text-amber-400 mb-3">Experiência Única</h3>
                <p className="text-gray-300">Uma celebração do churrasco com apresentações exclusivas e momentos inesquecíveis.</p>
              </div>
              <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
            </div>
          </div>

          <button 
            onClick={handleCardClick}
            className="mx-auto mt-12 block bg-amber-500 hover:bg-amber-600 
                     text-white px-12 py-4 rounded-lg font-bold 
                     transition-all duration-300 transform hover:scale-105
                     shadow-lg shadow-amber-500/20"
          >
            Garanta seu ingresso agora
          </button>

          {/* Seção das Bandas */}
          <div className="mt-16 space-y-8 text-center">
            <h2 className="text-3xl md:text-4xl font-bold text-white">
              Atrações Musicais
            </h2>
            <div className="space-y-4 text-gray-300">
              <p className="text-lg">
                Aproveite a melhor música ao vivo durante o evento:
              </p>
              <ul className="space-y-4">
                <li className="flex flex-col items-center">
                  <span className="text-amber-400 text-xl font-bold">Gui Tozin</span>
                  <span>13:30 às 15:30hrs</span>
                </li>
                <li className="flex flex-col items-center">
                  <span className="text-amber-400 text-xl font-bold">Smooth and Dizzy</span>
                  <span>16:30 às 18:30hrs</span>
                </li>
                <li className="flex flex-col items-center">
                  <span className="text-amber-400 text-xl font-bold">Re Gugli e Banda</span>
                  <span>19:30 às 21:30hrs</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Meio;
