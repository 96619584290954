import React from 'react';
import { FaInstagram, FaTiktok, FaYoutube, FaLinkedin, FaFacebook, 
         FaMapMarkerAlt, FaWhatsapp, FaClock } from 'react-icons/fa';

const Footer = () => {
  const socialLinks = [
    {
      title: "Instagram",
      url: "https://www.instagram.com/cantobardeparrilla/",
      icon: <FaInstagram className="text-2xl" />
    },
    {
      title: "TikTok",
      url: "https://www.tiktok.com/@cantobardeparrilla",
      icon: <FaTiktok className="text-2xl" />
    },
    {
      title: "YouTube",
      url: "https://www.youtube.com/@Canto-BardeParrilla-io8ls",
      icon: <FaYoutube className="text-2xl" />
    },
    {
      title: "LinkedIn",
      url: "https://www.linkedin.com/company/canto-bar-de-parrilla/",
      icon: <FaLinkedin className="text-2xl" />
    },
    {
      title: "Facebook",
      url: "https://www.facebook.com/canto.bardeparrilla",
      icon: <FaFacebook className="text-2xl" />
    },
    {
      title: "WhatsApp",
      url: "https://wa.me/554191683897",
      icon: <FaWhatsapp className="text-2xl" />
    },
    {
      title: "Google Maps",
      url: "https://maps.app.goo.gl/YNdrbiqZ7DyFYSeq6",
      icon: <FaMapMarkerAlt className="text-2xl" />
    },
  ];

  return (
    <footer className="bg-stone-900/90 text-white py-12">
      <div className="container mx-auto px-4">
        <div className="max-w-4xl mx-auto space-y-12">
          {/* Endereço e Horários */}
          <div className="flex flex-col items-center space-y-8">
            <div className="space-y-4 text-center">
              <h3 className="text-2xl font-bold text-amber-400">Localização</h3>
              <a 
                href="https://maps.app.goo.gl/jpvhBM6hqmYHKd9r6"
                target="_blank"
                rel="noopener noreferrer"
                className="flex flex-col items-center gap-3 hover:text-amber-400 transition-colors duration-200"
              >
                <FaMapMarkerAlt className="text-3xl" />
                <div>
                  <p className="text-lg">R. Marcelino Champagnat, 602</p>
                  <p className="text-gray-300">Mercês, Curitiba - PR</p>
                </div>
              </a>
            </div>

          </div>

          {/* Redes Sociais */}
          <div className="flex justify-center flex-wrap gap-4">
            {socialLinks.map((link, index) => (
              <a
                key={index}
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
                title={link.title}
                aria-label={link.title}
                className="p-3 bg-[#843742]/20 hover:bg-[#843742]/30 
                         rounded-full transition-all duration-200 backdrop-blur-sm
                         hover:scale-110 hover:rotate-6"
              >
                {link.icon}
              </a>
            ))}
          </div>
          
          {/* Copyright */}
          <div className="text-sm text-gray-400 text-center border-t border-gray-800 pt-8">
            <p>© {new Date().getFullYear()} Canto Bar de Parrilla</p>
            <p>Todos os direitos reservados</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
