import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Bio from './components/Bio';
import Hero from './components/landingpages/1anocanto/Hero';
import Meio from './components/landingpages/1anocanto/Meio';
import Footer from './components/landingpages/1anocanto/Footer';
import VideoSection from './components/landingpages/1anocanto/VideoSection';
import GaleriaSlider from './components/landingpages/1anocanto/GaleriaSlider';
import StorySection from './components/landingpages/1anocanto/StorySection';

const App = () => {
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Bio />} />
        <Route 
          path="/1anocanto" 
          element={
            <>
              <Hero />
              <VideoSection />
              <GaleriaSlider />
              <StorySection /> {/* Nova seção adicionada */}
              <Meio />
              <Footer />
            </>
          } 
        />
      </Routes>
    </Router>
  );
};

export default App;
