import React from 'react';

const VideoModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 bg-black/90 z-50 flex items-center justify-center p-4"
      onClick={onClose}
    >
      <div className="relative w-full max-w-md aspect-[9/16]">
        <button 
          onClick={onClose}
          className="absolute -top-12 right-0 text-white/80 hover:text-white text-xl font-medium px-4 py-2"
        >
          ✕
        </button>
        <iframe
          className="w-full h-full"
          src="https://www.youtube.com/embed/1KFRdPQ3iOY?autoplay=1&mute=0&controls=0&showinfo=0&rel=0&modestbranding=1&loop=1&playlist=1KFRdPQ3iOY&playsinline=1&enablejsapi=1"
          title="Vídeo Parrillada Canto"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default VideoModal;
