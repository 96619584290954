import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import bgImage from '../../../assets/images/backgroundhero.jpg';
import bannerEvento from '../../../assets/images/banner evento.png';
import logoRounded from '../../../assets/images/canto rounded.png';
import VideoModal from '../../parts/VideoModal';
import CountdownCard from '../../parts/CountdownCard';
import { FaMapMarkerAlt, FaClock, FaMusic } from 'react-icons/fa';

const Hero = () => {
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);

  const handleCardClick = () => {
    window.dataLayer = window?.dataLayer || [];
    window.dataLayer.push({
      'event': 'Lead',
      'eventCategory': 'Ingresso',
      'eventAction': 'click',
      'eventLabel': '1° Parrillada Canto'
    });
    window.open('https://blackbirdsystem.com.br/evento/canto-1-ano-3011', '_blank');
  };

  return (
    <>
      <Helmet>
        <title>1ª Parrillada Canto | Canto Bar de Parrilla</title>
        <meta name="description" content="Celebre 1 ano do Canto Bar na maior parrillada de Curitiba. 10 horas de churrasco, música e experiências únicas no Dunna Club." />
        <meta property="og:title" content="1ª Parrillada Canto | Canto Bar de Parrilla" />
        <meta property="og:image" content={bannerEvento} />
      </Helmet>

      {/* Reduzindo o padding superior */}
      <div className="min-h-screen bg-[#1c1917] relative overflow-hidden pt-8 md:pt-16">
        {/* Conteúdo principal - reduzindo padding vertical */}
        <div className="relative z-10 min-h-screen flex items-center justify-center px-4 py-6 md:py-0">
          <div className="max-w-5xl mx-auto text-center space-y-4 md:space-y-8"> {/* reduzido space-y */}
            {/* Logo arredondada - ajustado apenas espaçamento vertical */}
            <div className="mb-2 md:mb-4"> {/* reduzido margin-bottom */}
              <a 
                href="https://reservation-widget.tagme.com.br/smartlink/6643c6f7fe872f00556e7460"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block w-24 h-24 md:w-32 md:h-32 mx-auto relative hover:opacity-90 transition-opacity duration-300"
              >
                <img 
                  src={logoRounded} 
                  alt="Canto Bar de Parrilla - Autêntica Parrilla Uruguaia e Argentina"
                  className="w-full h-full object-cover rounded-full shadow-lg p-1"
                  style={{
                    backgroundColor: '#843742'
                  }}
                />
              </a>
            </div>

            {/* Cabeçalho com animação */}
            <div className="space-y-4 md:space-y-6 animate-fade-in-down">
              <h1 className="text-4xl sm:text-5xl md:text-7xl font-bold text-white leading-tight tracking-tight">
                 1ª Parrillada{' '}
                <span className="text-amber-400 relative inline-block
                               animate-pulse-slow transform hover:scale-105 
                               transition-all duration-300">
                  Canto
                </span>{' '}
                Bar
              </h1>
              
              <p className="text-lg sm:text-xl md:text-2xl text-gray-300 max-w-3xl mx-auto
                          font-light leading-relaxed opacity-90">
                Evento das 12h às 22h com open food até 17h30,
                <br className="hidden md:block" /> 
                drinks especiais e música ao vivo
              </p>
            </div>

            {/* Informações do evento */}
            <div className="flex flex-wrap justify-center gap-4 md:gap-8 text-white/90
                          px-4 py-6 backdrop-blur-sm bg-black/20 rounded-xl
                          border border-white/10 animate-fade-in">
              <a 
                href="https://maps.app.goo.gl/jpvhBM6hqmYHKd9r6"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-3 transform hover:scale-105 transition-all duration-300"
              >
                <FaMapMarkerAlt className="text-amber-400 text-xl md:text-2xl" /> 
                <span className="text-sm md:text-base">Dunna Club - Mercês</span>
              </a>
              <div className="flex items-center gap-3 transform hover:scale-105 transition-all duration-300">
                <FaClock className="text-amber-400 text-xl md:text-2xl" /> 
                <span className="text-sm md:text-base">30/Nov - 12h às 22h</span>
              </div>
              <div className="flex items-center gap-3 transform hover:scale-105 transition-all duration-300">
                <FaMusic className="text-amber-400 text-xl md:text-2xl" /> 
                <span className="text-sm md:text-base">Shows ao vivo</span>
              </div>
            </div>

            {/* Countdown */}
            <div className="py-6 md:py-12 animate-fade-in-up">
              <CountdownCard />
            </div>

            {/* Removi os botões redundantes aqui */}

            <VideoModal 
              isOpen={isVideoModalOpen}
              onClose={() => setIsVideoModalOpen(false)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
