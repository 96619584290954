import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';

const GaleriaSlider = () => {
  const [isDragging, setIsDragging] = useState(false);
  const intervalRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  // Importar todas as imagens
  const images = Array.from({ length: 9 }, (_, i) => ({
    src: require(`./imagens/oqvaiencontras/1 (${i + 1}).jpg`),
    title: getTitleForImage(i)
  }));

  function getTitleForImage(index) {
    const titles = [
      "Comprar ingressos",
      "Comprar ingressos",
      "Comprar ingressos",
      "Comprar ingressos",
      "Comprar ingressos",
      "Comprar ingressos",
      "Comprar ingressos",
      "Comprar ingressos",
      "Comprar ingressos"
    ];
    return titles[index];
  }

  const handleCardClick = () => {
    window.dataLayer = window?.dataLayer || [];
    window.dataLayer.push({
      'event': 'Lead',
      'eventCategory': 'Galeria',
      'eventAction': 'click',
      'eventLabel': '1° Parrillada Canto'
    });
    window.open('https://blackbirdsystem.com.br/evento/canto-1-ano-3011', '_blank');
  };

  useEffect(() => {
    startAutoPlay();
    return () => clearInterval(intervalRef.current);
  }, [currentIndex]);

  const startAutoPlay = () => {
    if (intervalRef.current) clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      if (!isDragging) {
        setCurrentIndex((prev) => (prev + 1) % images.length);
      }
    }, 3000);
  };

  return (
    <section className="py-20 bg-stone-900">
      <div className="container mx-auto px-4">
        <div className="max-w-6xl mx-auto space-y-12">
          <div className="text-center space-y-4">
            <h2 className="text-3xl md:text-4xl font-bold text-white">
              O Melhor do <span className="text-amber-400">Canto</span> Está aqui
            </h2>
            <p className="text-gray-300 text-lg max-w-2xl mx-auto">
              Uma experiência gastronômica única com as melhores carnes e drinks em um ambiente acolhedor
            </p>
          </div>

          <motion.div 
            className="relative overflow-hidden cursor-grab active:cursor-grabbing"
            onMouseDown={() => setIsDragging(true)}
            onMouseUp={() => setIsDragging(false)}
            onMouseLeave={() => setIsDragging(false)}
            onTouchStart={() => setIsDragging(true)}
            onTouchEnd={() => setIsDragging(false)}
            onClick={handleCardClick}
          >
            <motion.div
              drag="x"
              dragConstraints={{ left: 0, right: 0 }}
              className="flex gap-4"
              animate={{
                x: -currentIndex * (300 + 16) // 300px width + 16px gap
              }}
              transition={{
                type: "tween",
                ease: "easeInOut",
                duration: 0.5
              }}
            >
              {images.map((image, index) => (
                <motion.div
                  key={index}
                  className="min-w-[300px] relative group"
                  whileHover={{ scale: 1.02 }}
                >
                  <img
                    src={image.src}
                    alt={image.title}
                    className="w-full h-[300px] object-cover rounded-lg shadow-xl"
                  />
                  <div className="absolute inset-0 bg-black/50 rounded-lg opacity-0 group-hover:opacity-100 
                                transition-opacity duration-300 flex items-center justify-center">
                    <p className="text-white text-xl font-bold text-center px-4">
                      {image.title}
                    </p>
                  </div>
                </motion.div>
              ))}
            </motion.div>

            <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex gap-2">
              {images.map((_, index) => (
                <button
                  key={index}
                  className={`w-2 h-2 rounded-full transition-all duration-300 
                           ${currentIndex === index ? 'bg-amber-400 w-4' : 'bg-white/50'}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    setCurrentIndex(index);
                  }}
                />
              ))}
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default GaleriaSlider;
