import React from 'react';
import Links from './parts/Links';
import { Helmet } from 'react-helmet';
import bgImage from '../assets/images/backgroundhero.jpg';
import bannerEvento from '../assets/images/banner evento.png';

const Bio = () => {
  return (
    <>
      <Helmet>
        <title>1ª Parrillada Canto | Canto Bar de Parrilla</title>
        <meta name="description" content="Celebre conosco a 1ª Parrillada Canto no Dunna Club. Experimente a autêntica parrilla uruguaia e argentina em Curitiba." />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="1ª Parrillada Canto | Canto Bar de Parrilla" />
        <meta property="og:description" content="Celebre conosco a 1ª Parrillada Canto no Dunna Club. A verdadeira experiência da parrilla em Curitiba." />
        <meta property="og:image" content={bannerEvento} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="1ª Parrillada Canto | Canto Bar de Parrilla" />
        <meta name="twitter:description" content="Celebre conosco a 1ª Parrillada Canto no Dunna Club. A verdadeira experiência da parrilla em Curitiba." />
        <meta name="twitter:image" content={bannerEvento} />
        
        {/* URL Canônica */}
        <link rel="canonical" href="https://www.cantobardeparrilla.com.br/bio" />
      </Helmet>
      <div className="min-h-screen bg-stone-900 relative overflow-hidden">
        {/* Background Image com blur */}
        <div 
          className="absolute inset-0 z-0"
          style={{
            backgroundImage: `url(${bgImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            filter: 'blur(8px) brightness(0.3)',
            transform: 'scale(1.1)', // Evita bordas brancas do blur
          }}
        />

        {/* Efeito de fogo */}
        <div className="absolute inset-0 z-1 opacity-50">
          <div className="fire-overlay"></div>
          <div className="fire-overlay fire-overlay-2"></div>
          <div className="fire-overlay fire-overlay-3"></div>
        </div>
        
        {/* Conteúdo principal */}
        <div className="relative z-10 min-h-screen bg-stone-900/50">
          <Links />
        </div>
      </div>
    </>
  );
};

export default Bio;
