import React, { useState } from 'react';
import CountdownCardBio from './CountdownCardBio';
import VideoModal from './VideoModal';
import logoRounded from '../../assets/images/canto rounded.png';
import { FaInstagram, FaTiktok, FaYoutube, FaLinkedin, FaFacebook, FaMapMarkerAlt, 
         FaUtensils, FaWhatsapp, FaClipboardList, FaCalendarAlt, FaClock } from 'react-icons/fa';

const Links = () => {
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const currentYear = new Date().getFullYear();

  const handleTracking = (action, label) => {
    window.dataLayer = window?.dataLayer || [];
    window.dataLayer.push({
      'event': action,
      'eventCategory': label,
      'eventAction': 'click',
      'eventLabel': label
    });
  };

  const handleMenuClick = (title) => {
    handleTracking('Lead', title);
  };

  const handleReservaClick = () => {
    handleTracking('Reserva', 'Reserva Mesa');
  };

  const handleContatoClick = () => {
    handleTracking('Contato', 'WhatsApp');
  };

  const handleCardClick = () => {
    // Disparar evento de analytics
    window.dataLayer = window?.dataLayer || [];
    window.dataLayer.push({
      'event': 'Lead',
      'eventCategory': 'Ingresso',
      'eventAction': 'click',
      'eventLabel': '1° Parrillada Canto'
    });
    
    // Abrir link em nova aba
    window.open('https://blackbirdsystem.com.br/evento/canto-1-ano-3011', '_blank');
  };

  const menuLinks = [
    {
      title: "Cardápio completo",
      url: "https://drive.google.com/file/d/19xb1xm09A082kK_g-YjsEYgG0HcoVN_1/view",
      icon: <FaUtensils className="text-xl" />,
      onClick: () => handleMenuClick("Cardápio completo")
    },
    {
      title: "Menu almoço",
      url: "https://drive.google.com/file/d/1UTYStVFW1Ig2ejBCMX5oLeuLpj-FKBfn/view",
      icon: <FaClipboardList className="text-xl" />,
      onClick: () => handleMenuClick("Menu almoço")
    },
  ];

  const actionLinks = [
    {
      title: "Faça sua reserva",
      url: "https://reservation-widget.tagme.com.br/smartlink/6643c6f7fe872f00556e7460",
      icon: <FaCalendarAlt className="text-xl" />,
      onClick: handleReservaClick
    },
    {
      title: "Fale conosco",
      url: "https://wa.me/554191683897?text=Olá%20Canto,%20acabei%20de%20ver%20sua%20bio.%20Me%20tira%20uma%20dúvida?",
      icon: <FaWhatsapp className="text-xl" />,
      onClick: handleContatoClick
    },
  ];

  const socialLinks = [
    {
      title: "Instagram",
      url: "https://www.instagram.com/cantobardeparrilla/",
      icon: <FaInstagram className="text-2xl" />
    },
    {
      title: "TikTok",
      url: "https://www.tiktok.com/@cantobardeparrilla",
      icon: <FaTiktok className="text-2xl" />
    },
    {
      title: "YouTube",
      url: "https://www.youtube.com/@Canto-BardeParrilla-io8ls",
      icon: <FaYoutube className="text-2xl" />
    },
    {
      title: "LinkedIn",
      url: "https://www.linkedin.com/company/canto-bar-de-parrilla/",
      icon: <FaLinkedin className="text-2xl" />
    },
    {
      title: "Facebook",
      url: "https://www.facebook.com/canto.bardeparrilla",
      icon: <FaFacebook className="text-2xl" />
    },
    {
      title: "Google Maps",
      url: "https://maps.app.goo.gl/YNdrbiqZ7DyFYSeq6",
      icon: <FaMapMarkerAlt className="text-2xl" />
    },
  ];

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-md mx-auto space-y-6">
        {/* Logo e Título */}
        <div className="text-center space-y-4">
          <a 
            href="https://reservation-widget.tagme.com.br/smartlink/6643c6f7fe872f00556e7460"
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleReservaClick}
            className="block w-32 h-32 mx-auto mb-4 relative hover:opacity-90 transition-opacity duration-300"
          >
            <img 
              src={logoRounded} 
              alt="Canto Bar de Parrilla - Autêntica Parrilla Uruguaia e Argentina"
              className="w-full h-full object-cover rounded-full shadow-lg p-1" // Reduzido de p-2 para p-1
              style={{
                backgroundColor: '#843742'
              }}
            />
          </a>
          <a 
            href="https://www.instagram.com/cantobardeparrilla/"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-amber-400 transition-colors duration-200"
          >
            <h1 className="text-4xl font-bold text-white mb-2">Canto Bar de Parrilla</h1>
          </a>
          <p className="text-amber-400 font-medium">Autêntica Parrilla Uruguaia e Argentina</p>
          <div className="w-24 h-1 bg-amber-500 mx-auto mt-4"></div>
        </div>

        <CountdownCardBio />
        
        <button
          onClick={() => setIsVideoModalOpen(true)}
          className="mx-auto block text-white/70 hover:text-white text-sm font-medium
                   transition-all duration-300 flex items-center gap-2 -mt-6"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
            <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z" />
          </svg>
          Ver trailer
        </button>

        <VideoModal 
          isOpen={isVideoModalOpen}
          onClose={() => setIsVideoModalOpen(false)}
        />

        {/* Cardápios e Ações */}
        <div className="space-y-3">
          {[...menuLinks, ...actionLinks].map((link, index) => (
            <a
              key={index}
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              onClick={link.onClick}
              className={`flex items-center px-4 py-3 text-white rounded-lg transition-all duration-200 shadow-lg
                       ${link.title.includes("conosco") ? "bg-green-700 hover:bg-green-800" : "bg-[#843742] hover:bg-[#943742]"}
                       hover:transform hover:scale-105`}
            >
              <span className="w-8">{link.icon}</span>
              <span className="flex-1 text-left">{link.title}</span>
            </a>
          ))}
        </div>

        {/* Informações */}
        <div className="text-white space-y-3 my-6 p-6 bg-[#843742]/10 rounded-lg backdrop-blur-sm border border-[#843742]/20">
          <a 
            href="https://maps.app.goo.gl/YNdrbiqZ7DyFYSeq6"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-start gap-2 hover:text-amber-400 transition-colors duration-200"
          >
            <FaMapMarkerAlt className="text-xl mt-1" />
            <span className="text-left">Alameda Prudente de Moraes, 1069 – Centro, Curitiba</span>
          </a>
          <div className="flex items-start gap-2">
            <FaClock className="text-xl mt-1 text-amber-400" />
            <div className="text-left">
              <p className="font-medium text-amber-400">Horário de funcionamento:</p>
              <p>Terça a sábado: 11h30 às 00h</p>
              <p>Domingo: 11h30 às 16h</p>
            </div>
          </div>
        </div>

        {/* Redes Sociais */}
        <div className="flex justify-center gap-4 mb-8">
          {socialLinks.map((link, index) => (
            <a
              key={index}
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              title={link.title}
              aria-label={link.title}
              className="p-3 text-center bg-[#843742]/20 hover:bg-[#843742]/30 
                       text-white rounded-full transition-all duration-200 backdrop-blur-sm
                       hover:scale-110 hover:rotate-6"
            >
              {link.icon}
            </a>
          ))}
        </div>

        {/* Copyright */}
        <div className="text-center text-white/60 text-sm mt-8">
          <p>&copy; {currentYear} Canto Bar de Parrilla. Todos os direitos reservados.</p>
        </div>
      </div>
    </div>
  );
};

export default Links;
