import React from 'react';
import fundadores from '../../../assets/images/cantobardeparrilla.webp';

const StorySection = () => {
  return (
    <section className="py-20 bg-stone-900 relative overflow-hidden">
      {/* Background effects */}
      <div className="absolute inset-0 bg-gradient-to-b from-stone-900/50 to-stone-900"></div>
      
      <div className="container mx-auto px-4 relative z-10">
        <div className="max-w-5xl mx-auto">
          {/* Grid container */}
          <div className="grid md:grid-cols-2 gap-12 items-center">
            {/* Imagem dos fundadores */}
            <div className="relative group">
              <div className="absolute inset-0 bg-amber-400 rounded-lg transform rotate-3 
                            group-hover:rotate-6 transition-transform duration-300"></div>
              <img 
                src={fundadores} 
                alt="Fundadores do Canto Bar de Parrilla" 
                className="relative rounded-lg shadow-xl transform -rotate-3 
                         group-hover:-rotate-6 transition-transform duration-300"
              />
            </div>

            {/* Texto e história */}
            <div className="space-y-6">
              <h2 className="text-4xl font-bold text-amber-400">
                Um ano de história e sabor
              </h2>
              
              <div className="prose prose-lg text-gray-300">
                <p>
                  O Canto Bar nasceu da paixão pelo churrasco e no prazer de reunir os amigos ao redor 
                  da brasa dos sócios Silvio Mariz Neto, Matheus Sperandio e Luiz Otávio Pires.
                </p>
                
                <p className="text-amber-400 font-medium">
                  "O Canto já é referência em boa gastronomia, drinks autorais e ambiente descontraído, 
                  com mesas na calçada, para um almoço executivo, happy hour ou jantar"
                </p>
                
                <p>
                  Para celebrar este primeiro ano de sucesso, realizamos a 1ª Parrillada Canto, 
                  um festival que reúne o melhor da gastronomia uruguaia e argentina em um ambiente 
                  ao ar livre, com música ao vivo e experiências únicas.
                </p>
              </div>

              {/* Destaques do evento */}
              <div className="grid grid-cols-2 gap-4 mt-8">
                <div className="bg-[#843742]/20 p-4 rounded-lg backdrop-blur-sm border border-[#843742]/30">
                  <h3 className="text-amber-400 font-bold mb-2">5 Estações</h3>
                  <p className="text-gray-300 text-sm">
                    Parrilla uruguaia e argentina com cortes nobres selecionados
                  </p>
                </div>
                
                <div className="bg-[#843742]/20 p-4 rounded-lg backdrop-blur-sm border border-[#843742]/30">
                  <h3 className="text-amber-400 font-bold mb-2">10 Horas</h3>
                  <p className="text-gray-300 text-sm">
                    De muito churrasco, drinks especiais e música ao vivo
                  </p>
                </div>
              </div>
              
              {/* CTA */}
              <button
                onClick={() => window.open('https://blackbirdsystem.com.br/evento/canto-1-ano-3011', '_blank')}
                className="mt-8 px-8 py-4 bg-amber-500 hover:bg-amber-600 
                         text-white rounded-lg font-bold shadow-lg
                         transition-all duration-300 transform hover:scale-105"
              >
                Garanta seu ingresso
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StorySection;
