import React, { useState, useEffect } from 'react';
import bannerEvento from '../../assets/images/banner evento.png';

const CountdownCardBio = () => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  useEffect(() => {
    const eventDate = new Date('2024-11-30T00:00:00');

    const calculateTimeLeft = () => {
      const now = new Date().getTime();
      const difference = eventDate - now;

      if (difference > 0) {
        setTimeLeft({
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60)
        });
      }
    };

    const timer = setInterval(calculateTimeLeft, 1000);
    return () => clearInterval(timer);
  }, []);

  const handleCardClick = () => {
    window.dataLayer = window?.dataLayer || [];
    window.dataLayer.push({
      'event': 'Lead',
      'eventCategory': 'Ingresso',
      'eventAction': 'click',
      'eventLabel': '1° Parrillada Canto'
    });
    
    window.open('https://www.cantobardeparrilla.com.br/1anocanto', '_blank');
  };

  return (
    <div 
      className="bg-[#843742]/20 rounded-lg p-4 text-center shadow-lg border border-[#843742]/30 backdrop-blur-sm cursor-pointer hover:bg-[#843742]/30 transition-all duration-300"
      onClick={handleCardClick}
    >
      <h2 className="text-xl font-bold text-white mb-2">1ª Parrillada Canto</h2>
      <p className="text-amber-400 text-sm mb-4">30 de Novembro no Dunna Club</p>
      
      <div className="mb-4">
        <img 
          src={bannerEvento} 
          alt="Banner 1ª Parrillada Canto" 
          className="w-full rounded-lg shadow-lg hover:opacity-90 transition-opacity duration-300"
        />
      </div>

      <div className="grid grid-cols-4 gap-2 mb-4">
        <div className="bg-[#843742]/40 p-2 rounded-lg backdrop-blur-sm">
          <span className="block text-xl font-bold text-white">{timeLeft.days}</span>
          <span className="text-xs text-amber-400">Dias</span>
        </div>
        <div className="bg-[#843742]/40 p-2 rounded-lg backdrop-blur-sm">
          <span className="block text-xl font-bold text-white">{timeLeft.hours}</span>
          <span className="text-xs text-amber-400">Horas</span>
        </div>
        <div className="bg-[#843742]/40 p-2 rounded-lg backdrop-blur-sm">
          <span className="block text-xl font-bold text-white">{timeLeft.minutes}</span>
          <span className="text-xs text-amber-400">Min</span>
        </div>
        <div className="bg-[#843742]/40 p-2 rounded-lg backdrop-blur-sm">
          <span className="block text-xl font-bold text-white">{timeLeft.seconds}</span>
          <span className="text-xs text-amber-400">Seg</span>
        </div>
      </div>

      <button className="w-full bg-green-600 hover:bg-green-700 text-white font-bold py-3 px-4 
                       rounded-lg transition-all duration-300 shadow-lg text-sm
                       animate-pulse hover:animate-none hover:scale-105">
        Garantir Ingresso - Lotes limitados
      </button>
    </div>
  );
};

export default CountdownCardBio;